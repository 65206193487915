import type { MouseEvent } from "react"
import { useEffect } from "react"
import { X } from "react-feather"
import { cx } from "class-variance-authority"

import { useSnackbarStore } from "@/utils/useSnackbar"

/*
    TODO Add Dynamic Positioning
    TODO Add More Color Variation
    TODO Run Custom Function After Close
    TODO Add More Animation Variation
    TODO Add Custom Hide Time
    TODO Prevent Duplicate
    TODO Custom Component
*/

const Snackbar = () => {
  const {
    message,
    variant,
    hasClose,
    customCloseText,
    customTimer,
    closeSnackbar,
    onCustomClose,
    messageClassNames,
  } = useSnackbarStore((state) => state)
  const isOpen = Boolean(message)

  const getVariant = () => {
    switch (variant) {
      case "error":
        return "bg-error50"
      case "success":
        return "bg-success50"
      default:
        return "bg-tertiary50"
    }
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        closeSnackbar()
      }, customTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const onClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onCustomClose && onCustomClose()
    closeSnackbar()
  }

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={cx(
        "fixed left-1/2 top-[18%] z-[999999] flex min-w-[600px] translate-x-[-50%] transform justify-between gap-4 rounded-4 p-4 shadow-4 transition-all",
        getVariant(),
        isOpen
          ? "translate-y-[-50%] opacity-100"
          : "translate-y-[10%] opacity-0"
      )}
    >
      <span
        className={cx(
          "text-sm font-normal leading-5 text-tertiary500",
          messageClassNames
        )}
      >
        {message}
      </span>
      {hasClose && (
        <button
          onClick={onClose}
          className={`cursor-pointer font-semibold ${
            customCloseText ? "text-tertiary500" : "text-tertiary300"
          }`}
        >
          {customCloseText ? <div>{customCloseText}</div> : <X />}
        </button>
      )}
    </div>
  )
}

export default Snackbar
