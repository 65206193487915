"use client"

import { cx } from "class-variance-authority"
import Image from "next/image"
import { MouseEvent, useEffect } from "react"
import { AlertCircle, File, XCircle } from "react-feather"
import { ProgressBar, Tooltip, useSnackbar } from "shared-ui"

import { generateEncryptionKeys, useUploadGcs } from "shared-utils"
import { type UploadAccountPublicRequest } from "../../federatedGql/graphql"
import { UPLOAD_CHAT_URL } from "../../graphql/mutations"
import { ALLOWED_IMAGE_EXTENSIONS } from "../../utils/common"
import { federatedGqlClient } from "../../utils/graphqlClient"

type NewUploadChatTypeParams = Omit<UploadAccountPublicRequest, "type">

const ChannelFilesPreviewUpload = ({
  item,
  onRemoveFile,
  onAddedFileToken,
}: {
  item: File
  onRemoveFile: (id: string, e?: MouseEvent<HTMLButtonElement>) => void
  onAddedFileToken: (token: string) => void
}) => {
  const fileExtension = item.name.split(".").pop() ?? ""
  const isImage = ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension)
  const { enqueueSnackbar } = useSnackbar()
  const { upload: uploadv2, percentage } =
    useUploadGcs<NewUploadChatTypeParams>({
      getSignedUrl: async (params) => {
        const base64encoded = Buffer.from(params.recipientKey ?? "").toString(
          "base64"
        )

        const uploadChatUrl = await federatedGqlClient.request(
          UPLOAD_CHAT_URL,
          {
            input: {
              fileName: params.fileName,
              mimeType: params.contentType,
              recipientKey: base64encoded,
            },
          }
        )

        if (uploadChatUrl.uploadChatUrl.__typename === "UploadChatUrlResp") {
          if (uploadChatUrl.uploadChatUrl.publicKey !== null) {
            return {
              token: uploadChatUrl.uploadChatUrl.jwtToken,
              signedUrl: uploadChatUrl.uploadChatUrl.uploadUrl,
              identifier: params.fileName,
              encryptedServerRecipient: uploadChatUrl.uploadChatUrl.publicKey,
              clientIdentity: params.clientIdentityKey ?? "",
              clientRecipient: params.recipientKey ?? "",
            }
          }
        }
      },
      onSuccessUpload(signUrlInfo) {
        onAddedFileToken(signUrlInfo.token)
      },
      onError(err) {
        enqueueSnackbar({
          type: "error",
          message: err,
          Icon: AlertCircle,
          actionButton: "close",
        })
        onRemoveFile(item.name)
      },
      errorMessage: {
        virusDetected:
          "Gagal menambahkan dokumen karena teridentifikasi mengandung virus.",
      },
    })

  useEffect(() => {
    generateEncryptionKeys().then(({ clientIdentity, clientRecipient }) => {
      uploadv2({
        file: item,
        recipientKey: clientRecipient,
        fileName: item.name,
        contentType: item.type,
        clientIdentityKey: clientIdentity,
        identifier: item.name,
      })
    })
  }, [])

  return (
    <div className="inline-block h-14 px-1">
      <div
        className={cx(
          "rounded-3 border-tertiary50 bg-tertiary25 hover:shadow-3 text-wrap group relative flex h-full items-center justify-center border-2 bg-white p-2 transition-shadow",
          isImage && "w-15"
        )}
      >
        {percentage === 0 && (
          <button
            className="text-tertiary50 absolute right-0 top-0 z-10 hidden -translate-y-1/2 translate-x-1/2 cursor-pointer group-hover:block"
            onClick={(e) => onRemoveFile(item.name, e)}
          >
            <XCircle fill="gray" className="h-5 w-5" />
          </button>
        )}
        {isImage ? (
          <Image
            alt="test imgage"
            src={URL.createObjectURL(item)}
            layout="fill"
            objectFit="contain"
            className="p-2"
          />
        ) : (
          <div className="inline-flex space-x-2">
            <div className="my-auto flex h-10 w-10 items-center justify-center rounded-full bg-[#F6F9FC]">
              <File fill="#818996" stroke="#FFF" className="h-6 w-6" />
            </div>
            <div>
              <Tooltip
                title={item.name}
                classNames={{
                  content: "break-words text-balance",
                }}
              >
                <p className="text-balance line-clamp-2 w-[172px] text-ellipsis break-words font-semibold">
                  {item.name}
                </p>
              </Tooltip>
              <p>{fileExtension.toUpperCase()}</p>
            </div>
          </div>
        )}
        {percentage > 0 && percentage <= 100 && (
          <div className="absolute flex h-full w-full items-center">
            <ProgressBar
              value={percentage}
              className="w-full"
              numberIndicatorStyle="fixed-bottom"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChannelFilesPreviewUpload
