import { type QueryObserverOptions, useQuery } from "@tanstack/react-query"
import {
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_DEV,
} from "@/app/(navigation-layout)/federated/queries"
import {
  type SearchProductsQuery,
  type SearchProductsDevQuery,
  type SearchProductsQueryVariables,
  type SearchProductsDevQueryVariables,
} from "@/federatedGql/graphql"
import { type GenericExtractor } from "@/types/type"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TSearchProductResult =
  | GenericExtractor<SearchProductsQuery["searchProducts"]>
  | GenericExtractor<SearchProductsDevQuery["searchProducts"]>

export type TCategoryAllLevelAggregations = NonNullable<
  Extract<
    NonNullable<TSearchProductResult["aggregations"]>[number],
    { __typename: "CategoryAllLevelAggregations" }
  >
>

export type TConsolidationAggregations = NonNullable<
  Extract<
    NonNullable<TSearchProductResult["aggregations"]>[number],
    { __typename: "ConsolidationAggregations" }
  >
>

type TUseSearchProduct = {
  filter: SearchProductsQueryVariables | SearchProductsDevQueryVariables
  options?: QueryObserverOptions<TSearchProductResult>
  enabledSearch?: boolean
  isUseDevelopmentQuery?: boolean
}

const useSearchProduct = ({
  filter,
  options,
  enabledSearch,
  isUseDevelopmentQuery,
}: TUseSearchProduct) => {
  const query = useQuery({
    queryKey: ["searchProduct", filter],
    enabled: enabledSearch,
    queryFn: async () => {
      const requestedQuery = isUseDevelopmentQuery
        ? SEARCH_PRODUCT_DEV
        : SEARCH_PRODUCT

      const res = await federatedGqlClient.request(requestedQuery, filter)

      if (res.searchProducts.__typename === "GenericError") {
        throw new Error(res.searchProducts.message)
      }

      return res.searchProducts
    },
    refetchOnWindowFocus: false,
    ...options,
  })
  return query
}

export default useSearchProduct
