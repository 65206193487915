"use client"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import HomepageSRPList from "./HomepageSRPList"
import { getHomePageSRPList } from "@/commons/constants/product"
import type { FeatureFlag } from "@/utils/getFlag"
import { MARKETPLACE_CONSOLIDATION_AND_FIX_PRICE } from "@/commons/constants/flags"

type TCarouselProps = {
  readonly featureFlags?: FeatureFlag
}

const Carousel = ({ featureFlags }: TCarouselProps) => {
  const isConsolidationAndFixPriceEnabled =
    featureFlags?.[MARKETPLACE_CONSOLIDATION_AND_FIX_PRICE]?.isEnabled

  const HOMEPAGE_SRP_LIST = getHomePageSRPList(
    isConsolidationAndFixPriceEnabled ?? false
  )

  return (
    <>
      {HOMEPAGE_SRP_LIST.map((srp, index) => {
        if (index > 1) {
          return (
            <LazyLoadComponent key={`${srp.title}-${index}`}>
              <HomepageSRPList {...srp} featureFlags={featureFlags} />
            </LazyLoadComponent>
          )
        }
        return (
          <HomepageSRPList
            {...srp}
            key={`${srp.title}-${index}`}
            featureFlags={featureFlags}
          />
        )
      })}
    </>
  )
}

export default Carousel
