import { Dropdown } from "shared-ui"
import { MoreVertical } from "react-feather"
import type { User } from "@sendbird/chat"
import { useChannelContext } from "@sendbird/uikit-react/Channel/context"
import Image from "next/image"
import Link from "next/link"
import React, { MouseEvent, useMemo, useState } from "react"
import { ZENDESK_URL } from "shared-config/clientConfig"

import { useCurrentUser, useSendbirdSdk } from "../../utils/sendbird"
import { useLocalStorageMessaging } from "notifications"

const AlertHeader = () => {
  return (
    <div className="bg-information50 text-tertiary500 flex h-12 items-center gap-3 px-5 text-sm">
      <Image
        src="/assets/icons/iconInfo.svg"
        height={20}
        width={20}
        alt="icon-info"
      />
      Percakapan ini akan direkam dan dimonitor oleh Sistem.
    </div>
  )
}

const ChannelHeader = ({
  user,
  setUser,
  slug,
}: {
  user?: User
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
  slug?: string | null
}) => {
  const currentUser = useCurrentUser()
  const sdk = useSendbirdSdk()
  const { currentGroupChannel } = useChannelContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { appType } = useLocalStorageMessaging()

  const toogle = () => setIsOpen((prev) => !prev)
  const onClose = () => setIsOpen(false)

  const getDataUser = () => {
    if (!currentGroupChannel?.url) return

    const arrUsersId = currentGroupChannel?.members.map((item) => item.userId)

    if (!arrUsersId?.length) return

    const query = sdk?.createApplicationUserListQuery({
      userIdsFilter: [...arrUsersId],
    })

    query?.next().then((users) => {
      const userTarget = users?.find(
        (item) => item.userId !== currentUser?.userId
      )

      setUser(userTarget)
    })
  }

  useMemo(() => getDataUser(), [currentGroupChannel?.url])

  const userOnline = user?.connectionStatus === "online"

  const getBackgrounColorStatus = () =>
    userOnline ? "bg-success500" : "bg-tertiary200"

  const handleCloseOnRedirect = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    // Need this because href on Link doesn't trigger for some reason
    if (typeof window) {
      window.open(ZENDESK_URL, "_blank")
    }

    onClose()
  }

  return (
    <div>
      <div
        className="border-tertiary50 bg-primary25 rounded-tr-6 flex items-center border-b p-3"
        style={{ height: "69px" }}
      >
        <Link
          data-test="chat-user-profile"
          id="chat-user-profile"
          className={`flex items-center ${
            appType === "SELLER" && "pointer-events-none"
          }`}
          href={`/${slug}`}
        >
          <div className="relative">
            <div className={`badge-status ${getBackgrounColorStatus()}`} />
            <Image
              data-test="chat-user-avatar"
              className="h-10 w-10 rounded-full"
              src={
                user?.plainProfileUrl ||
                currentGroupChannel?.coverUrl ||
                "https://via.placeholder.com/40x40"
              }
              alt="cover-channel"
              width={40}
              height={40}
            />
          </div>

          <div className="ml-3">
            <p
              data-test="chat-user-name"
              className="text-tertiary500 font-semibold"
            >
              {user?.nickname}
            </p>
            <p className="text-tertiary300 text-sm">
              {userOnline ? "Online" : "Offline"}
            </p>
          </div>
        </Link>

        <div className="ml-auto">
          <Dropdown
            trigger={
              <button onClick={toogle} className="outline-none">
                <MoreVertical size={20} />
              </button>
            }
            onOpenChange={onClose}
            open={isOpen}
            positionOffset={20}
            positionAlign="end"
          >
            <div className="px-5 py-4" style={{ width: "245px" }}>
              <Link
                data-test="chat-help-center"
                id="chat-help-center"
                href={ZENDESK_URL}
                className="text-tertiary500 block w-full text-sm"
                onClick={handleCloseOnRedirect}
              >
                Pusat Bantuan
              </Link>
            </div>
          </Dropdown>
        </div>
      </div>
      <AlertHeader />
    </div>
  )
}

export default ChannelHeader
