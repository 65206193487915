/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation UploadChatUrl($input: UploadChatUrlInput!) {\n    uploadChatUrl(input: $input) {\n      ... on UploadChatUrlResp {\n        uploadUrl\n        jwtToken\n        publicKey\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n": types.UploadChatUrlDocument,
    "\n  mutation UploadChatFile($input: UploadChatFileInput!) {\n    uploadChatFile(input: $input) {\n      ... on UploadChatFileResp {\n        result {\n          success\n          message\n          __typename\n        }\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n": types.UploadChatFileDocument,
    "\n  query GetInstitutionById($id: String!) {\n    getInstitutionByID(id: $id) {\n      ... on Institution {\n        __typename\n        company {\n          name\n          slug\n        }\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n": types.GetInstitutionByIdDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadChatUrl($input: UploadChatUrlInput!) {\n    uploadChatUrl(input: $input) {\n      ... on UploadChatUrlResp {\n        uploadUrl\n        jwtToken\n        publicKey\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UploadChatUrl($input: UploadChatUrlInput!) {\n    uploadChatUrl(input: $input) {\n      ... on UploadChatUrlResp {\n        uploadUrl\n        jwtToken\n        publicKey\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadChatFile($input: UploadChatFileInput!) {\n    uploadChatFile(input: $input) {\n      ... on UploadChatFileResp {\n        result {\n          success\n          message\n          __typename\n        }\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UploadChatFile($input: UploadChatFileInput!) {\n    uploadChatFile(input: $input) {\n      ... on UploadChatFileResp {\n        result {\n          success\n          message\n          __typename\n        }\n        __typename\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetInstitutionById($id: String!) {\n    getInstitutionByID(id: $id) {\n      ... on Institution {\n        __typename\n        company {\n          name\n          slug\n        }\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInstitutionById($id: String!) {\n    getInstitutionByID(id: $id) {\n      ... on Institution {\n        __typename\n        company {\n          name\n          slug\n        }\n      }\n      ... on GenericError {\n        reqId\n        code\n        message\n        __typename\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;