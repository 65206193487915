"use client"

import type { User } from "@sendbird/chat"
import { GroupChannel } from "@sendbird/chat/groupChannel"
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react"
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context"
import { EveryMessage, type ChannelListQueries } from "SendbirdUIKitGlobal"
import React, { useEffect, useMemo, useState } from "react"
import { useChatStore } from "../store/chatStore"
import { getTimestampChat } from "../utils/common"
import { useCurrentUser } from "../utils/sendbird"

import { useRouter, useSearchParams } from "next/navigation"
import { useLocalStorageMessaging } from "notifications"

export type UseChatParams = {
  sellerData?: {
    sellerId: string | undefined
    setSellerId: React.Dispatch<React.SetStateAction<string | undefined>>
  }
}

export type OptionsType = {
  label: string
  value: "all" | "read" | "unread"
}

const useChat = () => {
  const [channelUrl, setChannelUrl] = useState<string>("")
  const [selectedChannel, setSelectedChannel] = useState<GroupChannel>()
  const [searchKeyword, setSearchKeyword] = useState<string>("")
  const [latestMsgTimeStamp, setLatestMsgTimeStamp] = useState<number>()
  const [messages, setMessages] = useState<EveryMessage>()
  const [currentPartner, setCurrentPartner] = useState<User>()
  const [isEmptyChannel, setIsEmptyChannel] = useState(false)
  const [slug, setSlug] = useState<string>("")
  const [selectedChannelFilter, setSelectedChannelFilter] =
    useState<OptionsType>()

  const { seller, buyer, setBuyer, setMessageToSent } = useChatStore()
  const { appType } = useLocalStorageMessaging()
  const context = useSendbirdStateContext()
  const createGroup = sendbirdSelectors.getCreateGroupChannel(context)
  const { allChannels, initialized: isChannelInitialized } =
    useChannelListContext()
  const currentUsers = useCurrentUser()
  const router = useRouter()
  const params = useSearchParams()

  const targetChatId = params?.get("target")
  const sellerName = params?.get("nickname")
  const messageToSent = params?.get("message")

  const channelListQueries: ChannelListQueries = {
    channelListQuery: {
      nicknameContainsFilter: searchKeyword,
    },
  }

  const goToLatestMessage = () => {
    if (!selectedChannel) return

    const latestMessage = selectedChannel.lastMessage

    if (!latestMessage) return

    const latestTimestamp = Number(getTimestampChat(latestMessage.createdAt))

    setLatestMsgTimeStamp(latestTimestamp)
  }

  const getTargetChannelUrl = async ({
    targetId,
    name,
    currentUsers,
  }: {
    targetId?: string
    name?: string
    currentUsers?: User | null
  }) => {
    if (!targetId || !currentUsers?.userId) return ""

    const channelList = allChannels.map((ch) => {
      const channelMembers = ch.members.map((m) => {
        return m.userId
      })

      return {
        members: channelMembers,
        channelUrl: ch.url,
      }
    })

    const existingChannel = channelList.find(
      (obj) =>
        obj.members.includes(targetId) &&
        obj.members.includes(currentUsers.userId)
    )

    if (existingChannel) {
      return existingChannel.channelUrl
    }

    const newChannel = await createGroup({
      invitedUserIds: [targetId, currentUsers.userId],
      isPublic: false,
      name: `${name ?? targetId} ${currentUsers.nickname}`,
      // TODO uncomment when all target already have profile images
      // coverUrl: target.sellerImage,
    })

    return newChannel.url
  }

  if (appType === "BUYER") {
    useMemo(() => {
      if (currentPartner?.userId) {
        const metaData = currentPartner.metaData as { slug: string }
        setSlug(metaData.slug)
      }
    }, [appType, currentPartner])
  }

  useMemo(() => goToLatestMessage(), [selectedChannel, messages])

  useEffect(() => {
    if (targetChatId && appType === "SELLER") {
      setBuyer({
        buyerId: targetChatId ?? "",
      })
      router.push("/chat")
    }

    if (targetChatId && appType === "BUYER") {
      setMessageToSent(
        {
          sellerId: targetChatId ?? "",
          sellerName: sellerName ?? "",
        },
        messageToSent ?? ""
      )
      router.push("/chat")
    }
  }, [targetChatId])

  useEffect(() => {
    let channelFound = false

    if (seller && appType === "BUYER" && currentUsers && isChannelInitialized) {
      getTargetChannelUrl({
        targetId: seller.sellerId,
        name: seller.sellerName,
        currentUsers,
      }).then((url) => {
        if (!channelFound) {
          setChannelUrl(url)
        }
      })
    }

    return () => {
      channelFound = true
    }
  }, [seller, appType, currentUsers, isChannelInitialized])

  useEffect(() => {
    let channelFound = false

    if (buyer && appType === "SELLER" && currentUsers && isChannelInitialized) {
      getTargetChannelUrl({
        targetId: buyer.buyerId,
        currentUsers,
      }).then((url) => {
        if (!channelFound) {
          setChannelUrl(url)
        }
      })
    }

    return () => {
      channelFound = true
    }
  }, [buyer, appType, currentUsers, isChannelInitialized])

  return {
    channelUrl,
    selectedChannel,
    channelListQueries,
    searchKeyword,
    latestMsgTimeStamp,
    currentPartner,
    isEmptyChannel,
    seller,
    buyer,
    selectedChannelFilter,
    slug,
    setCurrentPartner,
    setSearchKeyword,
    setChannelUrl,
    setSelectedChannel,
    setMessages,
    setIsEmptyChannel,
    setSelectedChannelFilter,
  }
}

export default useChat
