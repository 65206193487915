"use client"

import { SendbirdError } from "@sendbird/chat"
import { MessageMetaArray } from "@sendbird/chat/message"
import {
  sendBirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react"
import { useChannelContext } from "@sendbird/uikit-react/Channel/context"
import { sanitize } from "isomorphic-dompurify"
import {
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type FormEvent,
  type KeyboardEvent,
  type MouseEvent,
} from "react"
import { AlertCircle } from "react-feather"
import { useSnackbar } from "shared-ui"

import config from "../config"
import { useUploadChatFile } from "../graphql/mutations"
import { useChatStore } from "../store/chatStore"
import {
  ALLOWED_FILE_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  MAX_FILE_IN_SINGLE_CHAT_UPLOAD,
  checkMimeType,
} from "../utils/common"
import { bannedUrlErrorCode, profanityErrorCode } from "../utils/sendbird"

const getImageDimensions = async (
  file: File
): Promise<{
  width: number
  height: number
}> =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve({ width: img.width, height: img.height })
    img.src = URL.createObjectURL(file)
  })

const useChannelInput = ({ channelUrl }: { channelUrl: string }) => {
  const [message, setMessage] = useState<string>("")
  const [files, setFiles] = useState<FileList>()
  const [isMessageInputError, setIsMessageInputError] = useState<boolean>(false)
  const [filesTokens, setFilesTokens] = useState<string[]>([])

  const { currentGroupChannel } = useChannelContext()
  const sendbirdStore = useSendbirdStateContext()
  const { enqueueSnackbar } = useSnackbar()
  const {
    selectedProductToAsk,
    setSelectedProductToAsk,
    order,
    setOrder,
    quotedMessage,
    setQuotedMessage,
    senderName,
    messageToSent,
    setMessageToSent,
  } = useChatStore()
  const { mutate: uploadChatFile } = useUploadChatFile()

  const sendUserMessage = sendBirdSelectors.getSendUserMessage(sendbirdStore)

  const messageRef = useRef<HTMLTextAreaElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { maxMessageLength, maxFileSize, maxFileSizeMb, maxFilePixels } = config

  const handleErrorSnackbar = (errorMessage: string) => {
    enqueueSnackbar({
      type: "error",
      message: errorMessage,
      Icon: AlertCircle,
      actionButton: "close",
    })
  }

  const handleFocus = () => {
    if (messageRef.current) {
      messageRef.current.focus({
        preventScroll: true,
      })
    }
  }

  const handleValidateFile = (validType: string[], filename: string) => {
    const extension = filename.split(".").pop()?.toLowerCase() ?? ""

    if (!extension || !validType.length || !validType.includes(extension))
      return false
    return true
  }

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setMessage(e.target.value)
  }

  const uploadFiles = async (rawFiles: FileList | File[] | null) => {
    const tempFiles = files
    const validFiles = new DataTransfer()
    const invalidFilesSize = new DataTransfer()
    const invalidFilesType = new DataTransfer()
    const invalidFilesLength = new DataTransfer()

    if (tempFiles?.length) {
      Array.from(tempFiles).map((file) => {
        validFiles.items.add(file)
      })
    }

    if (rawFiles?.length) {
      const filePromises = Array.from(rawFiles).map(async (rawFile) => {
        const fileSize = rawFile.size
        const isFileValid = handleValidateFile(
          ALLOWED_FILE_EXTENSIONS,
          rawFile.name
        )
        const isImage = ALLOWED_IMAGE_EXTENSIONS.includes(
          rawFile.name.split(".").pop()?.toLowerCase() ?? ""
        )
        const dimensions = isImage ? await getImageDimensions(rawFile) : null
        const isMimeTypeAllowedAndEqualExtension = await checkMimeType(rawFile)

        if (
          fileSize > maxFileSize ||
          (dimensions && dimensions.height * dimensions.width > maxFilePixels)
        ) {
          invalidFilesSize.items.add(rawFile)
        } else if (!isFileValid || !isMimeTypeAllowedAndEqualExtension) {
          invalidFilesType.items.add(rawFile)
        } else if (validFiles.items.length >= MAX_FILE_IN_SINGLE_CHAT_UPLOAD) {
          invalidFilesLength.items.add(rawFile)
        } else {
          validFiles.items.add(rawFile)
        }
      })
      await Promise.all(filePromises)
    }

    if (invalidFilesSize.files.length) {
      handleErrorSnackbar(
        `Gagal menambahkan ${invalidFilesSize.files.length} dokumen. Ukuran harus kurang dari ${maxFileSizeMb} MB`
      )
    }

    if (invalidFilesType.files.length) {
      if (invalidFilesType.files.length > 1) {
        handleErrorSnackbar(
          `${
            invalidFilesSize.files.length
          } Dokumen gagal ditambahkan. Format dokumen harus ${ALLOWED_FILE_EXTENSIONS.join(
            ","
          )}`
        )
      } else {
        handleErrorSnackbar(
          `Dokumen gagal ditambahkan. Tipe dokumen tidak valid`
        )
      }
    }

    if (invalidFilesLength.files.length) {
      handleErrorSnackbar(
        `Gagal menambahkan ${invalidFilesLength.files.length} dokumen. Jumlah maksimal ${MAX_FILE_IN_SINGLE_CHAT_UPLOAD} dokumen yang dapat ditambahkan sekaligus`
      )
    }

    setFiles(validFiles.files)

    if (fileRef.current) {
      fileRef.current.value = ""
    }

    if (messageRef.current) {
      messageRef.current.focus()
    }
  }

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()

    uploadFiles(e.target.files)
  }

  const handleClickChatTemplate = (chatInput: string) => {
    setMessage(chatInput)
    handleFocus()
  }

  const handleDeleteFile = (
    fileName: string,
    e?: MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (files) {
      const file = [...files]

      const indexToDelete = file.findIndex((value) => value.name === fileName)
      file.splice(indexToDelete, 1)
      setFilesTokens((prevState) =>
        prevState.filter((_, index) => index !== indexToDelete)
      )

      const dataTransfer = new DataTransfer()

      file.forEach((item) => {
        dataTransfer.items.add(item)
      })

      setFiles(dataTransfer.files)
    }
  }

  const isMessagePresent = Boolean(message.trim())
  const isFilePresent = Boolean(files?.length)
  const isProductOrOrderSelected = Boolean(
    selectedProductToAsk?.productId || order?.orderId
  )
  const areFilesAndTokensMismatch = Boolean(
    files?.length && files?.length !== filesTokens.length
  )
  const handleButtonDisabled = () =>
    isMessageInputError ||
    !(isMessagePresent || isFilePresent || isProductOrOrderSelected) ||
    areFilesAndTokensMismatch

  const handleRemoveProduct = () => {
    setSelectedProductToAsk?.(null, null)
  }

  const handleRemoveOrder = () => {
    setOrder(null, null)
  }

  const convertStringToAsterisk = (str: string) => {
    return str.replace(/./g, "*")
  }

  const messageMetaArray = [
    new MessageMetaArray({ key: "sender", value: [senderName] }),
  ]

  const handleFailedMessage = (error: SendbirdError) => {
    if (!currentGroupChannel) return

    if (
      error.code === profanityErrorCode ||
      error.code === bannedUrlErrorCode
    ) {
      sendUserMessage(currentGroupChannel, {
        message: convertStringToAsterisk(message),
        customType:
          error.code === profanityErrorCode ? "SENSITIVE" : "SENSITIVE_URL",
        metaArrays: messageMetaArray,
      })
    }
  }

  const handleSubmitMessage = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!currentGroupChannel) return

    if (message && !selectedProductToAsk && !order) {
      sendUserMessage(
        currentGroupChannel,
        quotedMessage?.messageId && !filesTokens.length
          ? {
              message,
              parentMessageId: quotedMessage.messageId,
              isReplyToChannel: true,
              metaArrays: messageMetaArray,
            }
          : {
              message,
              metaArrays: messageMetaArray,
            }
      ).onFailed((error: SendbirdError) => {
        handleFailedMessage(error)
      })
    }

    if (selectedProductToAsk && !order) {
      const payload = {
        product: {
          name: sanitize(selectedProductToAsk.productName || ""),
          imageUrl: selectedProductToAsk.productImage,
          price: selectedProductToAsk.productPrice,
          slug: selectedProductToAsk.productSlug,
          sellerSlug: selectedProductToAsk.sellerSlug,
        },
      }

      sendUserMessage(currentGroupChannel, {
        message,
        customType: "PRODUCT",
        data: JSON.stringify(payload),
        metaArrays: messageMetaArray,
      })
        .onSucceeded(() => {
          setSelectedProductToAsk?.(null, null)
          sendUserMessage(currentGroupChannel, {
            message: `${sanitize(payload.product.name)}/${
              payload.product.price
            }/${payload.product.slug}`,
            customType: "HIDDEN",
            metaArrays: messageMetaArray,
          })
        })
        .onFailed((error: SendbirdError) => {
          handleFailedMessage(error)
          setSelectedProductToAsk?.(null, null)
        })
    }

    if (order) {
      const payload = {
        orderId: order.orderId,
        orderNumber: order.orderNumber,
        orderPrice: order.orderPrice,
        orderKey: order.orderKey,
        type: order.type,
        negotiationId: order.negotiationId,
        productData: {
          name: sanitize(order.productData.productName || ""),
          imageUrl: order.productData.productImage,
          price: order.productData.productPrice,
        },
      }

      sendUserMessage(currentGroupChannel, {
        message,
        customType: "ORDER",
        data: JSON.stringify(payload),
        metaArrays: messageMetaArray,
      })
        .onSucceeded(() => {
          setOrder(null, null)
          sendUserMessage(currentGroupChannel, {
            message: `${payload.orderNumber}/${payload.productData.name}/${payload.orderPrice}`,
            customType: "HIDDEN",
            metaArrays: messageMetaArray,
          })
        })
        .onFailed((error: SendbirdError) => {
          handleFailedMessage(error)
          setOrder(null, null)
        })
    }

    if (filesTokens.length) {
      uploadChatFile(
        {
          channelUrl,
          token: filesTokens,
          ...(quotedMessage?.messageId && {
            parentMessageId: quotedMessage.messageId.toString(),
          }),
        },
        {
          onError(error) {
            if (error.message === "IMAGE_MODERATION") {
              sendUserMessage(currentGroupChannel, {
                message: "",
                customType: "SENSITIVE_IMAGE",
                metaArrays: messageMetaArray,
              })
            } else {
              handleErrorSnackbar(error?.message || JSON.stringify(error))
            }
          },
        }
      )
    }

    if (messageRef.current) {
      messageRef.current.value = ""
    }

    if (fileRef.current) {
      fileRef.current.value = ""
    }

    setMessage("")
    setFiles(undefined)
    setFilesTokens([])
    setQuotedMessage(null)
    setMessageToSent(null, "")
  }

  const resetInputOnChannelChange = () => {
    setMessage("")
    setFiles(undefined)
    setMessageToSent(null, "")
  }

  const handleOnPressEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !isMessageInputError) {
      e.preventDefault()
      e.stopPropagation()
      if (buttonRef.current) {
        buttonRef.current.click()
      }
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = event.clipboardData.items
    const pastedImageFiles: File[] = []
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        event.preventDefault()
        const blob = item.getAsFile()
        if (blob) {
          const file = new File([blob], blob.name, { type: blob.type })
          pastedImageFiles.push(file)
        }
      }
    }

    uploadFiles(pastedImageFiles)
  }

  useMemo(() => {
    resetInputOnChannelChange()
    handleFocus()
  }, [channelUrl])

  const handleAddFileToken = (token: string) =>
    setFilesTokens((prevState) => [...prevState, token])

  useMemo(() => {
    if (message.length > maxMessageLength) {
      setIsMessageInputError(true)
    } else {
      setIsMessageInputError(false)
    }
  }, [message])

  useMemo(() => {
    if (messageToSent) {
      setMessage(messageToSent)
      handleFocus()
    }
  }, [messageToSent])

  return {
    files,
    message,
    messageRef,
    fileRef,
    buttonRef,
    selectedProductToAsk,
    order,
    maxMessageLength,
    isMessageInputError,
    setSelectedProductToAsk,
    handleUploadFile,
    handleMessage,
    handleClickChatTemplate,
    handleDeleteFile,
    handleButtonDisabled,
    handleRemoveProduct,
    handleRemoveOrder,
    handleOnPressEnter,
    onSubmit: handleSubmitMessage,
    setMessage,
    setFiles,
    handleAddFileToken,
    handlePaste,
  }
}

export default useChannelInput
