type ValidInstitutionType =
  | "PROVINSI"
  | "KABUPATEN"
  | "KOTA"
  | "KEMENTERIAN"
  | "LEMBAGA"

const institutionTypeToCode: Record<ValidInstitutionType, string> = {
  PROVINSI: "PD",
  KABUPATEN: "PD",
  KOTA: "PD",
  KEMENTERIAN: "KL",
  LEMBAGA: "KL",
}

export default function getInstitutionTypeCode(
  institutionType: string | undefined
): string | null {
  if (institutionType && institutionType in institutionTypeToCode) {
    return institutionTypeToCode[institutionType as ValidInstitutionType]
  }
  return null
}
