"use client"

import { cx } from "class-variance-authority"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { type Session } from "next-auth"
import { useState } from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import { Dropdown } from "shared-ui"

import SelectAddress from "./components/SelectAddress"

import loginStatus from "@/authentication/helpers/loginStatus"
import { MARKETPLACE_MINI_HEADER_DROPDOWN } from "@/commons/constants/flags"
import checkIsBendahara from "@/utils/checkIsBendahara"
import {
  headerNavbarMenu,
  headerNavbarMenuNew,
  hiddenHeaderAddressPath,
} from "@/utils/common"
import { type FeatureFlag } from "@/utils/getFlag"

type THeaderNavbarProps = {
  session: Session | null
  readonly featureFlags: FeatureFlag
}

const transactionListItem = [
  {
    text: "Negosiasi",
    url: "/negotiation",
  },
  {
    text: "Kompetisi",
    url: "/mini-competition/my-competition",
  },
  {
    text: "Persetujuan PPK",
    url: "/review-ppk",
  },
  {
    text: "Pesanan",
    url: "/orders",
  },
]

const HeaderNavbar = ({ session, featureFlags }: THeaderNavbarProps) => {
  const pathName = usePathname()
  const [isTransactionListOpen, setIsTransactionListOpen] = useState(false)
  const [isHeaderNavDropdownOpen, setIsHeaderNavDropdownOpen] = useState<
    number | null
  >(null)
  const isLoggedIn = loginStatus(!!session?.user?.id) === "authenticated"

  const isShowDaftarTransaksi = Boolean(
    isLoggedIn && !checkIsBendahara(session?.user?.role)
  )

  const isMiniHeaderDropdownEnabled =
    featureFlags[MARKETPLACE_MINI_HEADER_DROPDOWN]?.isEnabled

  const isOnHiddenAddressPath = hiddenHeaderAddressPath.includes(pathName || "")

  return (
    <div className="fixed top-0 z-[100] mx-auto h-[34px] w-full bg-tertiary25">
      <div className="mx-auto flex w-full items-center px-11">
        <div className="flex h-[34px] w-full justify-between gap-6">
          <div className="flex items-center gap-6">
            {isMiniHeaderDropdownEnabled
              ? headerNavbarMenuNew.map((item, idx) => (
                  <>
                    {item.link === "#" && item.links.length ? (
                      <Dropdown
                        key={`header-navbar-dropdown-${idx}`}
                        open={isHeaderNavDropdownOpen === idx}
                        className="ml-[20%] !min-w-max"
                        trigger={
                          <button
                            id="mini-header-dropdown"
                            className="inline-flex cursor-pointer items-center gap-2 text-xs text-tertiary300 outline-none"
                          >
                            <span>{item.name}</span>
                            {isHeaderNavDropdownOpen === idx ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            )}
                          </button>
                        }
                        onOpenChange={() =>
                          setIsHeaderNavDropdownOpen((prev) =>
                            prev === idx ? null : idx
                          )
                        }
                      >
                        <div className="flex flex-col gap-4 p-4">
                          {item.links.map((link, index) => (
                            <Link
                              key={index}
                              className="my-1 text-sm font-normal text-tertiary700 hover:underline"
                              href={link.link}
                              target="_blank"
                              onClick={() => setIsHeaderNavDropdownOpen(null)}
                            >
                              {link.name}
                            </Link>
                          ))}
                        </div>
                      </Dropdown>
                    ) : (
                      <Link
                        href={item.link}
                        target="_blank"
                        className="text-xs text-tertiary300"
                      >
                        {item.name}
                      </Link>
                    )}
                  </>
                ))
              : headerNavbarMenu.map((item, idx) => (
                  <Link
                    key={`header-navbar-${idx}`}
                    href={item.link}
                    target="_blank"
                    className="text-xs text-tertiary300"
                  >
                    {item.name}
                  </Link>
                ))}
          </div>
          {isShowDaftarTransaksi && (
            <div className="flex items-center gap-2">
              <div className={cx(!isOnHiddenAddressPath && "pr-3")}>
                <Dropdown
                  onOpenChange={() => setIsTransactionListOpen((prev) => !prev)}
                  open={isTransactionListOpen}
                  className="!min-w-fit"
                  trigger={
                    <button
                      id="transaction-list-dropdown-button"
                      className="inline-flex cursor-pointer items-center gap-2 text-xs font-semibold text-secondary500 outline-none"
                    >
                      <span>Daftar Transaksi</span>
                      {isTransactionListOpen ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </button>
                  }
                >
                  <div className="flex flex-col gap-4 p-4">
                    {transactionListItem.map((item, index) => (
                      <Link
                        key={index}
                        className="hover: text-sm font-normal text-tertiary700 hover:underline"
                        href={item.url}
                        onClick={() => setIsTransactionListOpen(false)}
                      >
                        {item.text}
                      </Link>
                    ))}
                  </div>
                </Dropdown>
              </div>
              {!isOnHiddenAddressPath && <SelectAddress />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderNavbar
