import { useRouter } from "next/navigation"
import { useSnackbar } from "shared-ui"
import { SelectedProduct, SellerChat, useChatStore } from "../store/chatStore"

const useRedirectToChat = (
  hasPersona: boolean,
  institutionId?: string,
  sellerName?: string
) => {
  const router = useRouter()
  const { setOrder, setSeller, setSelectedProductToAsk, setBuyer } =
    useChatStore()
  const { enqueueSnackbar } = useSnackbar()

  const orderRedirectToChat = (orderParams: any) => {
    if (hasPersona) {
      setOrder(
        {
          sellerId: institutionId ?? "",
          sellerName: sellerName ?? "",
        },
        orderParams
      )
      router.push("/chat")
    } else {
      enqueueSnackbar({
        message: "Gagal mendapatkan data persona",
        type: "error",
      })
    }
  }

  const sellerDiscussionsRedirectToChat = (messageToSent: string) => {
    if (hasPersona) {
      const targetId = institutionId

      setSeller({
        sellerId: targetId ?? "",
        sellerName: sellerName ?? "",
      })

      window.open(
        `/chat?target=${targetId}&message=${messageToSent}&name=${sellerName}`,
        "_blank"
      )
    } else {
      enqueueSnackbar({
        message: "Gagal mendapatkan data persona",
        type: "error",
      })
    }
  }

  const buyerDiscussionsRedirectToChat = (buyerId: string) => {
    setBuyer({ buyerId })

    window.open(`/chat?target=${buyerId}`, "_blank")
  }

  const askSellerProductRedirectToChat = (
    product: SelectedProduct | null,
    seller: SellerChat
  ) => {
    setSelectedProductToAsk(product, seller)
  }

  const askBuyerNegotiationRedirectToChat = (buyerId: string) => {
    setBuyer({ buyerId })
    router.push("/chat")
  }

  return {
    orderRedirectToChat,
    sellerDiscussionsRedirectToChat,
    askSellerProductRedirectToChat,
    askBuyerNegotiationRedirectToChat,
    buyerDiscussionsRedirectToChat,
    isEnabled: hasPersona,
  }
}

export default useRedirectToChat
