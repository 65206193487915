"use client"

import { cx } from "class-variance-authority"
import Link from "next/link"
import { type Session } from "next-auth"
import { useEffect } from "react"
import { Button } from "shared-ui"

import CategoryMenu from "./components/CategoryMenu"
import ChatIconNav from "./components/ChatIconNav"
import IconNav from "./components/IconNav"
import NotificationSection from "./components/NotificationSection"
import SearchBarSuggestion from "./components/SearchBarSuggestion"
import UserDropdown from "./components/UserDropdown"

import HeaderLogoImage from "./HeaderLogoImage"
import { signIn } from "@/authentication/authService"
import loginStatus from "@/authentication/helpers/loginStatus"
import { REGISTER_URL } from "@/config/client"
import ROUTE from "@/config/route"
import { useNotificationBadgeStore } from "@/store/useNotificationBadgeStore"
import useCheckPathname from "@/utils/useCheckPathname"

export type TProfileInfo = {
  fullName: string
  role: string
  profilePictureUrl: string
}

interface IHeaderProps {
  session: Session | null
  cartCount: number
}

const Header = ({ session, cartCount }: IHeaderProps) => {
  const isHideBar = useCheckPathname([
    "pdf-viewer",
    "form-negotiation/product-sold-log",
    "negotiation/product-sold-log",
    "checkout/location",
    "checkout/product",
  ])

  const isEnterpriseRedirect = useCheckPathname(["/auth/enterprise-redirect"])
  const isLoggedIn = loginStatus(!!session?.user?.id) === "authenticated"
  const { cartBadgeTotal, setCartNotification } = useNotificationBadgeStore(
    (state) => state
  )

  const goToLoginPage = () => {
    signIn("auth0")
  }

  useEffect(() => {
    setCartNotification(cartCount)
  }, [cartCount])

  return (
    <div
      className={cx(
        "fixed z-[100] h-[76px] w-full bg-primary25",
        !isEnterpriseRedirect && "top-[34px]"
      )}
    >
      <div className="shadow-1">
        <div className="mx-auto flex h-[74px] w-full items-center gap-6 px-11">
          <Link href="/">
            <HeaderLogoImage width={101.2} height={14.8} />
          </Link>
          {!isHideBar && (
            <>
              {!isEnterpriseRedirect && (
                <>
                  <CategoryMenu />
                  <div className="flex-grow">
                    <SearchBarSuggestion />
                  </div>
                </>
              )}
              {isLoggedIn && (
                <section className="ml-auto flex items-center gap-2">
                  <>
                    <div className="flex items-center gap-4">
                      <NotificationSection />
                      <ChatIconNav />
                      {!isEnterpriseRedirect && (
                        <IconNav
                          nameIcon="ShoppingCart"
                          countNotification={cartBadgeTotal}
                          pathname={ROUTE.cart()}
                        />
                      )}
                    </div>

                    <hr className="h-0 w-6 rotate-90 border-tertiary50 " />
                  </>

                  {session?.user?.id && <UserDropdown session={session} />}
                </section>
              )}
              {!isLoggedIn && (
                <div className="flex items-center">
                  <Button
                    id="header-login-btn"
                    variant="outline1"
                    className="mr-4"
                    onClick={goToLoginPage}
                  >
                    Masuk
                  </Button>

                  <Link href={REGISTER_URL} target="_blank">
                    <Button id="header-register-btn" variant="solid">
                      Daftar
                    </Button>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
