import { useQuery } from "@tanstack/react-query"

import {
  GET_CART_LIST_ITEMS,
  GET_CART_LIST_ITEMS_DEV,
} from "../federated/queries"
import {
  type GetCartListItemsQuery,
  type GetCartListItemsQueryVariables,
  type GetCartListItemsDevQuery,
  type GetCartListItemsDevQueryVariables,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"
import { IS_PRODUCTION } from "shared-utils"

export type TCartListItem =
  | Extract<GetCartListItemsQuery["cartListV2"], { __typename: "Cart" }>
  | Extract<GetCartListItemsDevQuery["cartListV2"], { __typename: "Cart" }>

type TUseGetCartItemsProps = {
  regionCode: GetCartListItemsQueryVariables | GetCartListItemsDevQueryVariables
  isEnabled?: boolean
  isUseDevelopmentQuery?: boolean
}

const useGetCartItems = ({
  regionCode,
  isEnabled = true,
  isUseDevelopmentQuery = false,
}: TUseGetCartItemsProps) => {
  return useQuery<TCartListItem>({
    queryKey: ["cartListItem", { regionCode }],
    queryFn: async () => {
      const requestedQuery =
        isUseDevelopmentQuery || !IS_PRODUCTION
          ? GET_CART_LIST_ITEMS_DEV
          : GET_CART_LIST_ITEMS

      const result = await federatedGqlClient.request(requestedQuery, {
        regionCode: regionCode as string,
      })
      if (result.cartListV2.__typename == "GenericError") {
        throw result.cartListV2
      }
      return result.cartListV2
    },
    retry: 1,
    enabled: isEnabled,
  })
}

export default useGetCartItems
