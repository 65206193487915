import {
  IS_CANARY_PREPROD,
  IS_DEVELOPMENT,
  IS_PREPRODUCTION,
  IS_PRODUCTION,
  IS_STAGING,
} from "shared-utils"

export const LIMIT_ALL_TRANSACTION_VALUE = "ORDER-LIMIT_ALL_TRANSACTION_VALUE"
export const LIMIT_PD_TRANSACTION_VALUE = "ORDER-LIMIT_PD_TRANSACTION_VALUE"
export const PAY_SIMULATION_BUTTON = "PAYMENT-PAY_SIMULATION_BUTTON"
export const REMOVE_REGION_FORM_ADDRESS = "LOGISTIC-REMOVE_REGION_FORM_ADDRESS"
export const VALIDATE_LOGISTIC_KILL_SWITCH =
  "PRODUCT-VALIDATE_LOGISTIC_KILL_SWITCH"
export const SELLER_ADDENDUM = "ORDER-ALLOW_SELLER_TO_PROPOSE_ADDENDUM"
export const PAYMENT_COA_FLOW = "PAYMENT_COA_FLOW"
export const PAYMENT_NEW_FLOW = "PAYMENT-NEW_FLOW"
export const HIDE_CANCEL_ORDER_BUTTON = "ORDER-HIDE_CANCEL_ORDER_BUTTON"
export const ADDENUM_QTY_ZERO = "ORDER-ADDENDUM_QUANTITY_ZERO"
export const ORDER_TERMINATION = "ORDER-ALLOW_INTERNAL_TO_TERMINATE_ORDER"
export const SHOW_BYPASS_SAKTI_SIMULATION = "PAYMENT-BYPASS_SAKTI_INTEGRATION"
export const ORDER_RATING_REVIEW = "PRODUCT-ORDER_RATING_REVIEW"
export const USE_GOOGLE_MAP_VERSION_2 = "USE_GOOGLE_MAP_VERSION_2"
export const BLACKLIST_CONSUMER_PLATFORM_INTEGRATION =
  "BLACKLIST-BUYER_CONSUMER_PLATFORM_INTEGRATION"
export const VALIDATION_BUTTON_IS_LOCK_PAYMENT =
  "PAYMENT-VALIDATION_IS_LOCK_PAYMENT"
export const MULTISHIPMENT = "MULTISHIPMENT"
export const IMPROVEMENT_SOLD_PRICE = "MARKETPLACE-IMPROVEMENT_SOLD_PRICE"
export const PDP_REMOVE_DEFAULT_LOCATION_VALIDATION =
  "MARKETPLACE-PDP_REMOVE_DEFAULT_LOCATION_VALIDATION"
export const PAYMENT_UPLOAD_TAX_DEDUCTION = "PAYMENT-UPLOAD_TAX_DEDUCTION"
export const PAYMENT_TAX_AMOUNT_RECOMMENDATION =
  "PAYMENT-TAX_AMOUNT_RECOMMENDATION"
export const MARKETPLACE_CART_IMPROVEMENT = "MARKETPLACE-CART_IMPROVEMENT"
export const MARKETPLACE_PRODUCT_PRINCIPAL_CONSTRUCTION =
  "MARKETPLACE-PRODUCT_PRINCIPAL_CONSTRUCTION"
export const MARKETPLACE_MINI_HEADER_DROPDOWN =
  "MARKETPLACE-MINI_HEADER_DROPDOWN"
export const MARKETPLACE_HIDE_LOCAL_PRODUCT_ON_EMPTY_ADDRESS =
  "MARKETPLACE-HIDE_LOCAL_PRODUCT_ON_EMPTY_ADDRESS"
export const MARKETPLACE_PRODUCT_SHOW_LOWEST_PRICE_IF_HAS_VARIANT =
  "MARKETPLACE-PRODUCT_SHOW_LOWEST_PRICE_IF_HAS_VARIANT"
export const MARKETPLACE_REMOVE_HARDCODED_FILTER_KLPD =
  "MARKETPLACE-REMOVE_HARDCODED_FILTER_KLPD"
export const MULTISHIPMENT_MP =
  IS_DEVELOPMENT || IS_STAGING ? "MULTISHIPMENT_DEV_MP" : "MULTISHIPMENT"
export const MULTISHIPMENT_REVIEW_PPK_STANDALONE =
  "MULTISHIPMENT-REVIEW_PPK_STANDALONE"
export const MULTISHIPMENT_ZERO_SHIPMENT_FEE = "MULTISHIPMENT-ZERO_SHIPMENT_FEE"
export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"
export const ENABLE_CONSTRUCTION_MINICOM =
  "MINI-COMPETITION_ENABLE_CONSTRUCTION"
export const MARKETPLACE_EPURCHASING_AGREEMENT =
  "MARKETPLACE-EPURCHASING_AGREEMENT"
export const MULTISHIPMENT_JASA_DIGITAL = "MULTISHIPMENT_JASA_DIGITAL"
export const PRODUCT_PRINCIPAL_SECTORAL_CONSTRUCTION =
  "MARKETPLACE-PRODUCT_PRINCIPAL_CONSTRUCTION"
export const BUYER_MINI_COMPETITION_ENABLED_NON_ITEMIZED =
  "BUYER-MINI-COMPETITION-ENABLED_NON_ITEMIZED"
export const MARKETPLACE_VALIDATION_SAMEDAY = "MARKETPLACE-VALIDATION_SAMEDAY"
export const MARKETPLACE_NONKLPD_SPSE = "MARKETPLACE-NONKLPD_SPSE"
export const ORDER_LETTER_ATTACHMENT = "MARKETPLACE-ORDER_LETTER_ATTACHMENT"
export const MULTISHIPMENT_MULTIPAYMENT = "MULTISHIPMENT_MULTIPAYMENT"
export const CHAT_REDIRECT_REVAMP = "CHAT_REVAMP-HOOKS"
export const ORDER_CONFIRMATION_DELIVERY_DOCUMENT =
  "ORDER-SERVICEDIGITAL_CONFIRMATION_DELIVERY_DOCUMENT"
export const MARKETPLACE_OUTSOURCING_FEE = "MARKETPLACE-OUTSOURCING_FEE"
export const MARKETPLACE_CONSOLIDATION_AND_FIX_PRICE =
  "MARKETPLACE-CONSOLIDATION_AND_FIX_PRICE"

export const FLAG_REPORT_PRODUCT = !IS_PRODUCTION || IS_CANARY_PREPROD

export const IS_PREPROD_OR_STAGING =
  IS_STAGING || IS_PREPRODUCTION || IS_DEVELOPMENT

export const ENTERPRISE_VALIDATION = "ACCOUNT-NONKLPD_ENTERPRISE_VALIDATION"
export const MARKETPLACE_SIPASTI_INFO_ADJUSTMENT =
  "MARKETPLACE-SIPASTI_INFO_ADJUSTMENT"
